.take-photo-container {
  .camMask-doc {


    top: 0;
    left: 0;
    z-index: 1;
    position: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

  }
}


.imgprev {
  object-fit: contain;
  height: auto;
}


.container-preview {
  height: 100%;

  .image-preview {
    width: 100%;
    object-fit: cover;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) // Sombra para dar un efecto de profundidad
  }

}


.camera-container {
  padding: 20px;
}