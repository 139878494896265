@import "../../styles/colors.scss";

.form-navbar {
  width: 100%;
  background-color: #F2EFED;
  height: 66px;
  padding: 20px 10px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;

  top: 0;
  left: 0;
  z-index: 1;
  border-bottom: 1px solid $blue_gray_200;

  .title {
    color: var(--blue-gray-blue-gray-800, #1e293b);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
    &.formone{
      margin-bottom: 25px;
    }

  }

  .back-button {
    position: absolute;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    top: 20px;
    left: 20px;
    // border: 2px solid $blue_gray_200;
    background: #F2EFED;
    &:hover() {
    }
    &:active {
      background: $blue_gray_200;
    }
  }
  .house-button {
    position: absolute;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    top: 20px;
    right: 20px;
    // border: 2px solid $blue_gray_200;
    background: #F2EFED;
    &:hover() {
    }
    &:active {
      background: $blue_gray_200;
    }
  }
}
