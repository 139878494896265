@import "../../../../styles/colors.scss";
.loanding {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  .card-datos {
    border: none !important;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    display: flex;
  }
}
