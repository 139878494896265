//BNP COLORS
$bnp_blue_light_50: #FBF4F0;
$bnp_blue_light_100: #F7E8E0;
$bnp_blue_light_200: #F4DED0;
$bnp_blue_light_300: #FFC4A1;
$bnp_blue_light_400: #FF9A5E; // Primary
$bnp_blue_light_500: #FF8136;
$bnp_blue_light_600: #F75C00;
$bnp_blue_light_700: #CE4D00;
$bnp_blue_light_800: #A53D00;
$bnp_blue_light_900: #380A00;




$bnp_blue_hard_50: #e8e7fd;
$bnp_blue_hard_100: #bbb8fa;
$bnp_blue_hard_200: #8e89f6;
$bnp_blue_hard_300: #6159f2;
$bnp_blue_hard_400: #342aef;
$bnp_blue_hard_500: #1a10d5;
$bnp_blue_hard_600: #140da6;
$bnp_blue_hard_700: #0f0976;
$bnp_blue_hard_800: #080541; // Primary
$bnp_blue_hard_900: #030218;

$light_blue_900: #01579b;
$light_blue_800: #0277bd;
$light_blue_700: #0288d1;
$light_blue_600: #039be5;
$light_blue_500: #03a9f4;
$light_blue_400: #29b6f6;
$light_blue_300: #4fc3f7;
$light_blue_200: #81d4fa;
$light_blue_100: #b3e5fc;
$light_blue_50: #ebf8fe;

$teal_900: #004d40;
$teal_800: #00695c;
$teal_700: #00796b;
$teal_600: #00897b;
$teal_500: #009688;
$teal_400: #27aa9e;
$teal_300: #55b9b0;
$teal_200: #8fd1cb;
$teal_100: #c5e7e4;
$teal_50: #f1f9f9;

$lime_900: #365314;
$lime_800: #3f6212;
$lime_700: #4d7c0f;
$lime_600: #65a30d;
$lime_500: #84cc16;
$lime_400: #a3e635;
$lime_300: #bef264;
$lime_200: #d9f99d;
$lime_100: #ecfccb;
$lime_50: #f7fee7;

$blue_gray_900: #0f172a;
$blue_gray_800: #1e293b;
$blue_gray_700: #334155;
$blue_gray_600: #475569;
$blue_gray_500: #64748b;
$blue_gray_400: #94a3b8;
$blue_gray_300: #cbd5e1;
$blue_gray_200: #e2e8f0;
$blue_gray_100: #f3f4f6;
$blue_gray_50: #f8fafc;

$red_900: #7f1d1d;
$red_800: #991b1b;
$red_700: #b91c1c;
$red_600: #dc2626;
$red_500: #ef4444;
$red_400: #f87171;
$red_300: #fca5a5;
$red_200: #fecaca;
$red_100: #fee2e2;
$red_50: #fef2f2;

$yellow_900: #713F12;
$yellow_800: #854D0E;
$yellow_700: #A16207;
$yellow_600: #CA8A04;
$yellow_500: #EAB308;
$yellow_400: #FACC15;
$yellow_300: #FDE047;
$yellow_200: #FEF08A;
$yellow_100: #FEF08A;
$yellow_50: #FEFCE8;

$chakra-colors-gray-50: #F7FAFC;
$chakra-colors-gray-100: #EDF2F7;
$chakra-colors-gray-200: #E2E8F0;
$chakra-colors-gray-300: #CBD5E0;
$chakra-colors-gray-400: #A0AEC0;
$chakra-colors-gray-500: #718096;
$chakra-colors-gray-600: #4A5568;
$chakra-colors-gray-700: #2D3748;
$chakra-colors-gray-800: #1A202C;
$chakra-colors-gray-900: #171923;

$white: #ffffff;
$surface_medium: #F8F9FC;

