.take-photo-container {
  height: calc(100svh - 66px);

  .camMask-selfie {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    position: fixed;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
   /*  background-image: url("../../resources/others/bigSelfieMask.svg");
    @media (max-width: 767px) {
      background-image: url("../../resources/others/largeSelfieMask.svg");
    }
    @media (max-width: 540px) {
      background-image: url("../../resources/others/regularSelfieMask.svg");
    }
    @media (max-width: 374px) {
      background-image: url("../../resources/others/smallSelfieMask.svg");
    } */
  }
}

.img {
  // margin-top: 20px;
  border-radius: 16px;
  object-fit: cover;
  width: 500px;
  height: 100%;
}

.image-container {
  margin-top: 24px;
  width: 115px;
  height: 160px;
  border-radius: 16px;
}

.error-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 44px;
}
.title {
  color: "#726F6E";
}