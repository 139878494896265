@import "../../styles/colors.scss";
@import "../../styles/responsiveSize";

.not-found-container {
  display: flex;
  height: 100vh;
  @include media(mobile) {
    flex-direction: column;
  }
  .blue-container {
    
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include media(mobile) {
      height: auto;
      width: 100%;
    }
    .newcheck-logo {
      padding: 64px 32px 12px 32px;
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 500px;
  
      @include media(mobile) {
        padding: 24px 32px 12px 32px;
      }
      img {
        width: 240px;
        @include media(mobile) {
          width: 100px;
        }
      }
    }

    .logo {
      padding: 56px 20px 20px 12px;
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
     
  

    }

    .notfound-logo {
      padding: 44px 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: max-content;
      flex: 1;
      @include media(mobile) {
        padding: 44px 32px;
        flex: none;
      }
      img {
        @include media(mobile) {
          width: 243px;
        }
      }
    }
  }
  .white-container {
    width: 50%;
    padding: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media(mobile) {
      width: 100%;
      padding: 32px 20px;
    }

    span {
      font-family: Poppins;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 76px;
      letter-spacing: -1.28px;
    }
    h3 {
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: -0.72px;
      color: $blue_gray_800;
    }
    p {
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.4px;
      color: $blue_gray_500;
    }

    .icon {
      padding-bottom: 16px;
      padding-top: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .not-found {
      font-size: 32px;
      line-height: 40px;
      color: #726F6E;
      font-weight: 600;
    }
    .texto {
      font-size: 11px;
      line-height: 16px;
      font-weight: 500;
    }
  }


}
