@import "../../../../styles/colors.scss";
.ocr-step {
  .card-datos {
    border: none !important;

    .heading {
      color: #1f1e1e;
      text-align: left;
      font-weight: 200;
      font-size: 18px;
    }
  }
  .link {
    color: $bnp_blue_light_400;
    &:hover {
      text-decoration: underline;
    }
  }
}
.footer-doc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  .circle-doc {
    width: 48px;
    height: 48px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7e8e0;
  }
  .si-correcto {
    border-radius: 16px !important;
    width: 100vw;
    font-size: 14px;
  }
}
